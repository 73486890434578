@import url(https://fonts.googleapis.com/css2?family=Lato&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Raleway:wght@300;400;700&display=swap);
html,
#root {
  height: 100%;
}
body {
  height: 100%;
  margin: 0;
  font-family: "Roboto", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
    "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

* {
  margin: 0;
  padding: 0;
}
.location-search-input {
  font-family: "Lato", sans-serif;
  width: 400px;
  height: 55px;
  background: none;
  border: 1px solid gray;
  border-radius: 10px;
  font-size: 1rem;
  padding: 10px;
}

.input-suggestion {
  font-family: "Lato", sans-serif;
  width: 500px;
  height: 60px;
  background-color: white;
  border-bottom: 1px black dotted;
  display: flex;
  align-items: center;
  font-size: 1rem;
  font-weight: bold;
  color: black;
  margin-left: 5rem;
}
.PhoneInputInput {
  width: 400px;
  height: 50px;
  font-size: 1rem;
  align-items: center;
  padding: 5px;
  border: 1px solid gray;
  border-radius: 10px;
}
.PhoneInputCountrySelect {
  width: 30px;
  height: 25px;
  border-radius: 10px;
}

